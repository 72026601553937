import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import { NegativeMobile } from 'core/components/Wrappers';

import VideoCover from './covers/Video';
import ImageCover from './covers/Image';

import styles from './index.styl';

const coverMap = {
  video: VideoCover,
};

function TopicCover({ content }) {
  const {
    topic_type: topicType,
  } = content.attributes;

  const Cover = coverMap[topicType] || ImageCover;

  return (
    <NegativeMobile>
      <div className={styles.cover}>
        <Cover content={content} />
      </div>
    </NegativeMobile>
  );
}

TopicCover.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(TopicCover);
