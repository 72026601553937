import PropTypes from 'prop-types';

import modelPropTypes from 'core/utils/prop-types/model';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Wave from 'site/components/Wave';

import styles from './index.styl';


export default function Incut(props) {
  const {
    parentProps: {
      theme,
    },
    block: {
      attributes: { body },
    },
  } = props;

  const waveProps = {
    width: 70,
    height: 26,
    stroke: theme.colors.accent,
    strokeWidth: 12,
    className: styles.wave,
  };

  return (
    <div className={styles.incut}>
      <style jsx>{`
        .${styles.incut}
          font-family ${theme.fonts.text}
      `}</style>
      <Wave {...waveProps} />
      <MarkdownWrapper>
        {body}
      </MarkdownWrapper>
      <Wave {...waveProps} />
    </div>
  );
}

Incut.propTypes = {
  block: modelPropTypes(PropTypes.shape({
    body: PropTypes.string.isRequired,
  })).isRequired,
  parentProps: PropTypes.object,
};
