import PropTypes from 'prop-types';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';

import Gallery from 'core/components/Gallery';

export default function PhotoGallery({ block, parentProps }) {
  return (
    <div className='vikontGallery'>
      <style jsx>{`
        .vikontGallery
          :global(.zone)
            :global(.arrow)
              fill ${parentProps.theme.colors.primary}
            &:hover
              :global(.arrow)
                fill ${parentProps.theme.colors.content}
      `}</style>
      <Gallery
        photoGallery={block}
        disableReloadButton
        withSmoothHeightChange
        imageSizeAsContainer
      />
    </div>
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
  parentProps: PropTypes.object,
};
