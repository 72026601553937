import palette from './palette';

const colors = {
  primary: palette.white,
  layout: palette.white,
  content: palette.flyByNight,
  active1: palette.white,
  active2: palette.white,
  accent: palette.gray200,
  divider: palette.gray200,
  gray200: palette.gray200,
  hint: palette.gray100,
  placeholder: palette.gray300,
  babyBlossom: palette.babyBlossom,
  caption: palette.white,
  credits: palette.gray400,
  black: palette.black,
  flyByNight: palette.flyByNight,
  theme: palette.cascara,
};

const button = {
  primary: {
    idle: {
      color: colors.active1,
      background: 'transparent',
      border: `1px solid ${colors.active1}`,
    },

    hover: {
      color: colors.content,
      background: colors.active1,
      border: `1px solid ${colors.active1}`,
    },

    active: {
      color: colors.active1,
      background: colors.active1,
      border: `1px solid ${colors.active1}`,
    },

    disabled: {
      color: colors.gray200,
      background: colors.active1,
      border: `1px solid ${colors.gray200}`,
    },
  },
};

export default {
  colors,
  controls: {
    button,
  },
};
