import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import ContentBackground from 'core/components/ContentBackground';
import GridFeed from 'core/components/GridFeed';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import ThemeSwitch from 'core/components/ThemeSwitch';
import { Indent } from 'core/components/Wrappers';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import ShapesTopCorners from 'site/components/ShapesTopCorners';

import CardSimple from 'site/cards/CardSimple';

import styles from './index.styl';


function News({ content, isMobile }) {
  return (
    <ThemeSwitch name='dark'>
      <ContentBackground className={styles.news}>
        <ShapesTopCorners position='right' />
        <div className={styles.content}>
          <GridFeed
            title='Новости'
            card={CardSimple}
            content={content}
            gap={isMobile ? '15px' : '30px 40px'}
            {...!isMobile && {
              gridTemplateColumns: '3fr 3fr 3fr 1fr',
              gridTemplateAreas: `
                'a b c .'
                'd e f .'
              `,
            }}
          />
          <Indent top={isMobile ? 15 : 30} />
          <div className={styles.allNews}>
            <Link type='primary' to='/news'>
              <Button size='small'>
                Еще новости
              </Button>
            </Link>
          </div>
        </div>
      </ContentBackground>
      <div className={styles.shape} />
    </ThemeSwitch>
  );
}

News.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
};

export default withBreakpoint(News);
