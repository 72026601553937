import PropTypes from 'prop-types';

import ThemeSwitch from 'core/components/ThemeSwitch';

import { NegativeMobile } from 'core/components/Wrappers';

import styles from './index.styl';


function CardMainWrapper(props) {
  const {
    card: Card,
    ...others
  } = props;

  return (
    <ThemeSwitch name='dark'>
      <NegativeMobile>
        <Card {...others} />
        <div className={styles.shape} />
      </NegativeMobile>
    </ThemeSwitch>
  );
}

CardMainWrapper.propTypes = {
  card: PropTypes.func,
};

export default CardMainWrapper;
