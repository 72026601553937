import PropTypes from 'prop-types';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import readMore from 'core/components/TopicContent/blocks/readMore';

import Wave from 'site/components/Wave';

import { CardReadMoreM, CardReadMoreS } from 'site/cards/ReadMore';

import styles from './index.styl';


function ReadMore(props) {
  const {
    block,
    theme,
    isDesktop,
  } = props;

  const Card = isDesktop ? CardReadMoreM : CardReadMoreS;

  return (
    <div className={styles.readMore}>
      <Wave
        width={isDesktop ? 120 : 70}
        height={isDesktop ? 22 : 12}
        strokeWidth={isDesktop ? 10 : 6}
        stroke={theme.colors.orange}
        className={styles.wave}
      />
      {readMore(
        block,
        {
          title: null,
          singleCard: Card,
          feedCard: Card,
          drumCard: Card,
        }
      )}
    </div>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(withTheme(ReadMore));
