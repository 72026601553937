import { Fragment } from 'react';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { prepareWidgetsBlock } from 'core/utils/content';

import Link from 'core/components/Link';
import TopicContent from 'core/components/TopicContent';
import ColumnLayout from 'core/components/ColumnLayout';
import { withBreakpoint } from 'core/components/breakpoint';

import * as siteWidgets from 'site/widgets'; // eslint-disable-line import/no-namespace

import styles from './index.styl';


const blocks = prepareWidgetsBlock(siteWidgets);

const relationships = resolveRelationships(['content', 'imported_topic'], {}, {
  content: {
    widgets: [],
  },
});

function SiteTopicContent({ isDesktop, content, hasReadMore }) {
  const {
    content: {
      widgets: contentWidgets,
    },
    imported_topic: importedTopic,
  } = relationships(content);

  /**
   * Для топиков импортированных из RSS (в таких топиках есть слово `imported` в URL),
   * показываем ссылку на оригинал топика.
   */
  const MoreDetailsLink = importedTopic?.link && (
    <Link
      type='article'
      to={importedTopic.link}
      title={content.attributes.headline}
    >
      Читать полностью
    </Link>
  );

  /**
   * Все виджеты "Материалы по теме" выносим в правую колонку.
   */
  if (isDesktop && hasReadMore) {
    const widgets = contentWidgets.filter(widget => widget.type !== 'readMore');
    const readMoreWidgets = contentWidgets.filter(widget => widget.type === 'readMore');

    return (
      <ColumnLayout
        rightColumn={(
          <Fragment>
            {readMoreWidgets.length > 0 && (
              <div className={styles.rightColumn}>
                {readMoreWidgets.map(widget => <siteWidgets.ReadMore key={widget.id} block={widget} />)}
              </div>
            )}
          </Fragment>
        )}
      >
        <TopicContent
          blocks={blocks}
          content={widgets}
        />
        {MoreDetailsLink}
      </ColumnLayout>
    );
  }

  return (
    <Fragment>
      <TopicContent
        blocks={blocks}
        content={contentWidgets}
      />
      {MoreDetailsLink}
    </Fragment>
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
  /**
   * Флаг под отдельную стеку топика, с виджетами "Читайте также"
   */
  hasReadMore: PropTypes.bool,
};

export default withBreakpoint(SiteTopicContent);
