import oneLine from 'common-tags/lib/oneLine';

import Montserrat500 from './Montserrat/Montserrat-Medium.woff';
import Montserrat500woff2 from './Montserrat/Montserrat-Medium.woff2';
import Montserrat500italic from './Montserrat/Montserrat-MediumItalic.woff';
import Montserrat500italicwoff2 from './Montserrat/Montserrat-MediumItalic.woff2';
import Montserrat600 from './Montserrat/Montserrat-SemiBold.woff';
import Montserrat600woff2 from './Montserrat/Montserrat-SemiBold.woff2';
import Onest700 from './Onest/Onest-Bold.woff';
import Onest700woff2 from './Onest/Onest-Bold.woff2';
import Onest900 from './Onest/Onest-Black.woff';
import Onest900woff2 from './Onest/Onest-Black.woff2';

const fonts = {
  text: 'Montserrat, sans-serif',
  display: 'Onest, sans-serif',
  get nuance() {
    return this.text;
  },
  faces: [
    {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontStyle: 'normal',
      src: oneLine`
        local('Montserrat-Medium'),
        local('Montserrat Medium'),
        url(${Montserrat500}) format('woff'),
        url(${Montserrat500woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontStyle: 'italic',
      src: oneLine`
        local('Montserrat-MediumItalic'),
        url(${Montserrat500italic}) format('woff'),
        url(${Montserrat500italicwoff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontStyle: 'normal',
      src: oneLine`
        local('Montserrat-SemiBold'),
        local('Montserrat SemiBold'),
        url(${Montserrat600}) format('woff'),
        url(${Montserrat600woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Onest',
      fontWeight: 700,
      fontStyle: 'normal',
      src: oneLine`
        local('Onest-Bold'),
        local('Onest Bold'),
        url(${Onest700}) format('woff'),
        url(${Onest700woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'Onest',
      fontWeight: 900,
      fontStyle: 'normal',
      src: oneLine`
        local('Onest-Black'),
        local('Onest Black'),
        url(${Onest900}) format('woff'),
        url(${Onest900woff2}) format('woff2')
      `,
    },
  ],
};

export default fonts;
