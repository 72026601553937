export const host = 'festival2024.ru';
export const siteUrl = 'https://' + host;

// indents
export const SIDE_INDENT = 49;
export const SIDE_INDENT_MOBILE = 20;
export const VERTICAL_INDENT = 40;
export const VERTICAL_INDENT_MOBILE = 20;

// limits
export const RUBRICS_PAGE_LIMIT = 7;

// sizes
export const LAYOUT_MAX_WIDTH = 1280;
export const LAYOUT_MAX_WIDTH_MOBILE = 600;
export const CONTENT_AREA = 660;
export const RIGHT_COLUMN_WIDTH = 300;
