import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint } from 'core/components/breakpoint';

import Image from 'core/components/Image';
import CaptionCredits from 'core/components/CaptionCredits';

import { LAYOUT_MAX_WIDTH_MOBILE, CONTENT_AREA } from 'site/constants';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

const MAX_HEIGHT = 440;

function TopicCover({ content, isMobile }) {
  const {
    image: {
      versions,
      caption,
      credits,
      credits_url: creditsUrl,
    },
  } = relationships(content);

  const maxWidth = isMobile ? LAYOUT_MAX_WIDTH_MOBILE : CONTENT_AREA;

  const { headline } = content.attributes;
  const altAndTitle = caption || headline;

  return (
    <>
      <div className={styles.topicCover}>
        <Image
          versions={versions}
          maxWidth={maxWidth}
          maxHeight={MAX_HEIGHT}
          placement='contain'
          alt={altAndTitle}
          title={altAndTitle}
        />
      </div>
      <div className={styles.caption}>
        <CaptionCredits
          caption={caption}
          credits={credits}
          creditsUrl={creditsUrl}
        />
      </div>
    </>
  );
}

TopicCover.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(TopicCover);
