import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import GridFeed from 'core/components/GridFeed';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { CardMainM, CardMainS } from 'site/cards/CardMain';
import { CardVerticalS, CardVerticalM } from 'site/cards/CardVertical';

import styles from './index.styl';


function OnePlusTwo({ content, isMobile }) {
  const cards = isMobile ? [CardMainS, CardVerticalM] : [CardMainM, CardVerticalS];

  return (
    <div className={styles.wrapper}>
      <GridFeed
        content={content}
        card={cards}
        {...isMobile
          ? { gap: '20px' }
          : {
            gridTemplateAreas: `
              'a b'
              'a c'
            `,
            gridTemplateColumns: '3fr 1fr',
            gap: '30px 35px',
          }}
      />
    </div>
  );
}

OnePlusTwo.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
};

export default withBreakpoint(OnePlusTwo);
