import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import GridFeed from 'core/components/GridFeed';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import CardQuiz from 'site/cards/CardQuiz';
import { CardVerticalM } from 'site/cards/CardVertical';


function MoreTopics(props) {
  const {
    quiz,
    isMobile,
    news,
  } = props;

  const cards = [];
  const listNewsAndQuiz = [];
  if (news[0]) {
    listNewsAndQuiz.push(news[0]);
    cards.push(CardVerticalM);
  }
  if (quiz) {
    listNewsAndQuiz.push(quiz);
    cards.push(CardQuiz);
  }
  listNewsAndQuiz.push(...news.slice(1));
  cards.push(CardVerticalM);

  return (
    <GridFeed
      card={cards}
      content={listNewsAndQuiz}
      gap={isMobile ? '20px' : '30px 40px'}
      {...!isMobile && { gridTemplateColumns: 'repeat(3, 1fr)' }}
    />
  );
}

MoreTopics.propTypes = {
  news: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  quiz: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(MoreTopics);
