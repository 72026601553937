import PropTypes from 'prop-types';

import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import modelPropTypes, { opinionAttributes } from 'core/utils/prop-types/model';

import Quote from 'site/icons/Quote';

import styles from './index.styl';

export default function Opinion(props) {
  const {
    block,
    parentProps,
  } = props;

  const {
    attributes: {
      author,
      body,
    },
  } = block;

  const { theme } = parentProps;

  const avatar = author?.attributes?.avatar;
  const name = author?.attributes?.name;
  const jobTitle = author?.attributes?.job_title;

  return (
    <div className={styles.opinion}>
      <style jsx>{`
        .${styles.opinion}
          background-color ${theme.colors.babyBlossom}
      `}</style>
      <Quote width={21} height={16} />
      <div className={styles.body}>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </div>
      {avatar && (
        <div className={styles.avatar}>
          <Image
            width={80}
            aspectRatio={1}
            src={avatar.attributes.versions.original}
          />
        </div>
      )}
      {name && <div className={styles.name}>{name}</div>}
      {jobTitle && <div className={styles.jobTitle}>{jobTitle}</div>}
      <Quote width={21} height={16} />
    </div>
  );
}

Opinion.propTypes = {
  parentProps: PropTypes.object,
  block: modelPropTypes(opinionAttributes).isRequired,
};
