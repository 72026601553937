import PropTypes from 'prop-types';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import resolve from 'core/resolver/resolve';

import { filterRequiredParams } from 'core/utils/api';

import Rubric from 'core/components/Rubric';
import withPageHocs from 'core/components/withPageHocs';
import Page from 'core/components/Page';
import { withBreakpoint } from 'core/components/breakpoint';
import H1 from 'core/components/H1';
import { PageIndent, Indent } from 'core/components/Wrappers';

import RubricTopics from 'site/components/RubricTopics';

import { CardMainSWithBg, CardMainMWithBg } from 'site/cards/CardMain';
import { CardVerticalM } from 'site/cards/CardVertical';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';


function RubricPage({ rawRubric, rawTopics, isMobile }) {
  return (
    <Page>
      <PageIndent top={isMobile ? 10 : 20}>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          limit={RUBRICS_PAGE_LIMIT}
          titleComponent={({ children }) => (
            <H1>{children}/</H1>
          )}
        >
          {({ topics }) => (
            <Indent bottom={isMobile ? 40 : 55} top={isMobile ? 0 : -30}>
              <RubricTopics topics={topics} />
            </Indent>
          )}
        </Rubric>
      </PageIndent>
    </Page>
  );
}

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: rawTopicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: filterRequiredParams([CardVerticalM, CardMainMWithBg, CardMainSWithBg], 'include'),
    fields: filterRequiredParams([CardVerticalM, CardMainMWithBg, CardMainSWithBg], 'fields'),
  }),
});

export default withBreakpoint(withPageHocs(dataProvider)(RubricPage));
