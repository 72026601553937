import palette from './palette';

const colors = {
  primary: palette.black,
  layout: palette.white,
  content: palette.white,
  active1: palette.circumorbitalRing,
  active2: palette.cascara,
  accent: palette.cascara,
  divider: palette.gray500,
  gray500: palette.gray500,
  gray600: palette.gray600,
  gray1000: palette.gray1000,
  hint: palette.gray400,
  placeholder: palette.gray600,
  orange: palette.orangeYellow,
  babyBlossom: palette.babyBlossom,
  caption: palette.black,
  credits: palette.gray600,
  black: palette.black,
  theme: palette.cascara,
};

const button = {
  primary: {
    idle: {
      color: colors.content,
      background: colors.active1,
      border: 'none',
    },

    hover: {
      color: colors.content,
      background: colors.active2,
      border: 'none',
    },

    active: {
      color: colors.content,
      background: colors.active2,
      border: 'none',
    },

    disabled: {
      color: colors.layout,
      background: '#e2e2e2',
      border: 'none',
    },
  },
};

export default {
  colors,
  controls: {
    button,
  },
};
