import PropTypes from 'prop-types';

import Video from 'core/components/Video';

import resolveRelationships from 'core/utils/relationships';

const relationships = resolveRelationships(['video'], {}, { video: {} });

export default function VideoCover(props) {
  const {
    video: {
      code, caption, provider,
    },
  } = relationships(props.content);

  return (
    <Video
      code={code}
      caption={caption}
      providerName={provider}
    />
  );
}

VideoCover.propTypes = {
  content: PropTypes.object,
};
