import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';

import Shape from './Shape.svg';

import styles from './index.styl';

function ShapesTopCorners({ isMobile, position, sendBackward, inInnerPages }) {
  return (
    <Shape
      width={isMobile ? 134 : 328}
      height={isMobile ? 374 : 696}
      className={cx(
        styles.figure_circle,
        styles['_' + position],
        sendBackward && styles._sendBackward,
        inInnerPages && styles._inInnerPages
      )}
    />
  );
}

ShapesTopCorners.propTypes = {
  isMobile: PropTypes.bool,
  /**
   * Модификатор для позиционирования фигуры.
   */
  position: PropTypes.oneOf(['left', 'right']),
  /**
   * Модификатор позиционирует фигру на заднем плане
   * z-index -1
   */
  sendBackward: PropTypes.bool,
  /**
   * Флаг сообщает что фигура находится на внутренних страницах
   * (не на главной).
   */
  inInnerPages: PropTypes.bool,
};

ShapesTopCorners.defaultProps = {
  position: 'left',
};

export default withBreakpoint(ShapesTopCorners);
