import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Link from 'core/components/Link';

import styles from './index.styl';


function NotFound({ theme }) {
  return (
    <div className={styles.notFound}>
      <style jsx>{`
        .${styles.title}
          color ${theme.colors.primary}
          font 700 40px/46px ${theme.fonts.display}
          :global(.mobile) &
            font 700 26px/31px ${theme.fonts.display}

        .toLeavePage
          color ${theme.colors.primary}
          font 500 18px/27px ${theme.fonts.text}
          :global(.mobile) &
            font 500 16px/24px ${theme.fonts.text}
      `}</style>
      <div className={styles.title}>Страница не найдена</div>
      <div className='toLeavePage'>
        Попробуйте начать <Link to='/'>с&nbsp;главной</Link>
      </div>
    </div>
  );
}

NotFound.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(NotFound);
