/**
 * Имена цветов заимствованы здесь https://icolorpalette.com/color/FDB913
 * Там же можно собрать более полную палитру оттенков.
 */
const palette = {
  black: '#000',
  white: '#fff',

  gray100: '#BFBFBF',
  gray200: '#BBB',
  gray300: '#B7B7B7',
  gray400: '#B3B3B3',
  gray500: '#AFAFAF',
  gray600: '#ABABAB',
  gray700: '#A7A7A7',
  gray800: '#A3A3A3',
  gray900: '#9F9F9F',
  gray1000: '#696969',
  flyByNight: '#201E57',
  babyBlossom: '#FBF4ED',
  circumorbitalRing: '#685EC6',
  cascara: '#F04937',
  orangeYellow: '#FDB913',
};

export default palette;
