import Button from 'core/components/Button';

import styles from './index.styl';

export default function ButtonBubbles({ children }) {
  return (
    <Button className={styles.buttonBubbles}>
      {children}
    </Button>
  );
}
