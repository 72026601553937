import PropTypes from 'prop-types';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { topicDateFormat } from 'core/utils/dates';

import Link from 'core/components/Link';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'published_at',
  'list_headline',
];


function CardSimple({ content, theme }) {
  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  return (
    <Link
      to={link}
      className={styles.link}
      type='secondary'
    >
      <style jsx>{`
        .${styles.headline}
        .${styles.time}
          font-family ${theme.fonts.text}
          color ${theme.colors.layout}
      `}
      </style>
      {(listHeadline || headline) && (
        <div className={styles.headline}>
          {listHeadline || headline}
        </div>
      )}
      {publishedAt && (
        <div className={styles.time}>{topicDateFormat(publishedAt)}</div>
      )}
    </Link>
  );
}

CardSimple.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object,
};

const Card = skip(withTheme(CardSimple));

Card.requiredPayloadFields = requiredPayloadFields;

export { CardSimple as StorybookComponent };

export default Card;
