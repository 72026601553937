import PropTypes from 'prop-types';
import cx from 'classnames';

import bindPropsHOC from 'core/components/bindProps';
import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { topicDateFormat } from 'core/utils/dates';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'published_at',
  'alternative_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

const MAX_WIDTH = 500;


function CardVertical(props) {
  const {
    content,
    size,
    theme,
    showAltHeadline,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    published_at: publishedAt,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content) || {};

  return (
    <div
      className={cx(
        styles.wrapper,
        styles[`_size_${size}`],
      )}
    >
      <style jsx>{`
        .${styles.wrapper}
          max-width ${MAX_WIDTH}px
        
        .${styles.altHeadline}
        .${styles.headline}
          color ${theme.colors.primary}
          font-family ${theme.fonts.text}

        .${styles.headline}
          font-family ${theme.fonts.display}

        .${styles.time}
          color ${theme.colors.placeholder}
          font-family ${theme.fonts.text}
      `}</style>
      <Link to={link} type='secondary'>
        <div className={styles.imageWrapper}>
          <SmartImage
            versions={versions}
            maxWidth={MAX_WIDTH}
            aspectRatio={1.5}
          />
        </div>
        {(listHeadline || headline) && (
          <div className={styles.headline}>
            {listHeadline || headline}
          </div>
        )}
        {altHeadline && showAltHeadline && (
          <div className={styles.altHeadline}>{altHeadline}</div>
        )}
        {publishedAt && (
          <div className={styles.time}>{topicDateFormat(publishedAt)}</div>
        )}
      </Link>
    </div>
  );
}

CardVertical.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm']),
  /** Параметр для управления отображением альтерантивного зага */
  showAltHeadline: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

CardVertical.defaultProps = {
  size: 'm',
};

const Card = skip(withTheme(CardVertical));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { Card as CardVerticalM };
export const CardVerticalS = bindPropsHOC({ size: 's' })(Card);

export default Card;

export { CardVertical as StorybookComponent };
