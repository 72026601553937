import { Fragment } from 'react';
import PropTypes from 'prop-types';

import WaveIcon from 'site/icons/Wave';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

export default function Wave(props) {
  const {
    width,
    height,
    stroke,
    strokeWidth,
    className,
  } = props;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .wave
          :global(path)
            stroke ${stroke}
            stroke-width ${strokeWidth}
      `}</style>
    </scope>
  );

  return (
    <Fragment>
      <WaveIcon
        width={width}
        height={height}
        className={scope.wrapClassNames('wave', className)}
      />
      <scope.styles />
    </Fragment>
  );
}

Wave.defaultProps = {
  width: 130,
  height: 22,
  stroke: '#FDB913',
  strokeWidth: 12,
};

Wave.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
