import deepmerge from 'core/libs/deepmerge';
import color from 'core/libs/color';

import CloseWhite from 'site/icons/CloseWhite';

import fonts from './fonts';

import {
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT_MOBILE,
} from 'site/constants';


export default function baseMobileTheme(atoms) {
  const layout = {
    maxWidth: '1280px',
    boxShadow: 'none',
    indents: {
      top: VERTICAL_INDENT_MOBILE,
      right: SIDE_INDENT_MOBILE,
      bottom: VERTICAL_INDENT_MOBILE,
      left: SIDE_INDENT_MOBILE,
    },
  };

  const shapkaLink = {
    font: `600 18px/26px ${fonts.text}`,
    padding: '15px 0',
  };

  const shapka = {
    background: 'transparent',
    link: shapkaLink,

    hideLogoInModal: true,

    dropdown: {
      link: shapkaLink,
    },

    logo: {
      width: 194,
      height: 70,
    },
    height: 110,

    menu: {
      hideBouesque: true,
      centered: true,
      itemDivider: 'none',
      navMargin: '20px 0 0',
    },

    burger: {
      width: 26,
      height: 17,
    },

    close: {
      width: 20,
      height: 20,
    },

    padding: `0 ${SIDE_INDENT_MOBILE}px`,
  };

  const topicTopline = {
    font: `12px/1 ${fonts.nuance}`,
  };

  const texts = {
    lead: {
      font: `500 16px/24px ${fonts.text}`,
    },

    body: {
      font: `500 16px/24px ${fonts.text}`,
    },

    title1: {
      font: `700 26px/31px ${fonts.display}`,
    },

    title2: {
      font: `700 24px/28px ${fonts.display}`,
    },
  };

  const vikontSpoiler = {
    title: {
      font: `700 16px/21px ${fonts.text}`,
    },
    block: {
      outerIndent: '20px 0',
      innerIndent: '20px',
    },
    body: {
      padding: '0 20px 20px',
    },
  };

  const gallery = {
    fullscreen: {
      imageInfo: {
        background: color(atoms.colors.black).alpha(0.5).string(),
        faderTransparency: color(atoms.colors.black).alpha(0).string(),
      },
    },
  };

  const socializator = {
    opened: {
      icon: CloseWhite,
    },
  };

  return deepmerge({
    controls: {
      shapka,
      topicTopline,
      vikontSpoiler,
      gallery,
      socializator,
    },
    layout,
    texts,
  }, atoms);
}
