import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { withBreakpoint } from 'core/components/breakpoint';
import GridFeed from 'core/components/GridFeed';

import CardMainWrapper from './CardMainWrapper';
import { CardMainSWithBg, CardMainMWithBg } from 'site/cards/CardMain';
import { CardVerticalM } from 'site/cards/CardVertical';


const desktopGridTemplate = `
  'a a a'
  'b c d'
`;

const CardMainDesk = (props) => (
  <CardMainWrapper card={CardMainMWithBg} {...props} />
);

const CardMainMob = (props) => (
  <CardMainWrapper card={CardMainSWithBg} {...props} />
);

function RubricTopics({ topics, isDesktop }) {
  const cards = isDesktop ? [CardMainDesk, CardVerticalM] : [CardMainMob, CardVerticalM];

  return (
    <GridFeed
      card={cards}
      content={topics}
      gap={isDesktop ? '30px' : '20px'}
      {...isDesktop && {
        gridTemplateAreas: desktopGridTemplate,
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: '1fr auto',
      }}
    />
  );
}

RubricTopics.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(RubricTopics);
