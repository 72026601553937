import configResolver from './shared-app-config';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';

import Layout from 'site/components/Layout';

import Main from './pages/main';
import Tag from 'site/pages/tag';
import Topic from 'site/pages/topic';
import Rubric from 'site/pages/rubric';
import NotFound from 'site/pages/notFound';

export default function Site() {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver();

  return (
    <App {...config}>
      <Layout>
        <BaseRoutes
          tags={Tag}
          main={Main}
          topic={Topic}
          rubrics={Rubric}
          notFound={NotFound}
        />
      </Layout>
    </App>
  );
}
