import PropTypes from 'prop-types';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import { filterRequiredParams } from 'core/utils/api';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import TagTopics from 'core/components/TagTopics';
import withPageHocs from 'core/components/withPageHocs';
import Page from 'core/components/Page';

import { PageIndent } from 'core/components/Wrappers';
import RubricTopics from 'site/components/RubricTopics';

import { CardMainSWithBg, CardMainMWithBg } from 'site/cards/CardMain';
import { CardVerticalM } from 'site/cards/CardVertical';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';


function TagPage({ tag, topics }) {
  return (
    <Page>
      <PageIndent>
        <TagTopics
          tag={tag}
          topics={topics}
          limit={RUBRICS_PAGE_LIMIT}
        >
          {props => <RubricTopics {...props} />}
        </TagTopics>
      </PageIndent>
    </Page>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: topicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: filterRequiredParams([CardVerticalM, CardMainMWithBg, CardMainSWithBg], 'include'),
    fields: filterRequiredParams([CardVerticalM, CardMainMWithBg, CardMainSWithBg], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(TagPage);
