import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';

import Link from 'core/components/Link';

import styles from './index.styl';

const relationships = resolveRelationships(['tags'], {}, { tags: [] });

export default function TopicTags({ content, className }) {
  const { tags } = relationships(content);

  if (tags.length === 0) return null;

  return (
    <div className={cx(styles.tags, className)}>
      {tags.map(item => {
        const { slug } = item.attributes;

        return (
          <Link
            type='tag'
            key={slug}
            to={`/label/${slug}`}
            className={styles.tag}
          >
            {item.attributes.title}
          </Link>
        );
      })}
    </div>
  );
}

TopicTags.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
};
